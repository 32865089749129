import Glide, { Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

const defaultParams = {
  type: 'slider',
  bound: true,
  focusAt: 0,
  startAt: 0,
  perView: 5,
  breakpoints: {
    768: { perView: 1 },
    1200: { perView: 3 },
  },
  rewind: false,
}

const singleImageParams = {
  type: 'slider',
  bound: true,
  focusAt: 0,
  startAt: 0,
  perView: 1,
  rewind: false,
  gap: 0,
  peek: 0,
}

const setParams = (glideElement) =>
  glideElement.classList.contains('single-image') ? singleImageParams : defaultParams

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.glide').forEach((glideElement) => {
    const glide = new Glide(glideElement, setParams(glideElement))

    glide.on('mount.before', () => {
      const calendarWrapper = document.querySelector('.home-calendar-wrapper')
      if (calendarWrapper) {
        calendarWrapper.classList.add('start')
      }
    })

    glide.on('run', () => {
      const isFirstSlide = glide.index === 0
      const isLastSlide = glide.index === glideElement.querySelectorAll('.glide__slide').length - glide._o.perView
      const nextButton = glideElement.querySelector('.glide__arrow--right')
      const prevButton = glideElement.querySelector('.glide__arrow--left')
      const calendarWrapper = document.querySelector('.home-calendar-wrapper')

      if (prevButton) {
        prevButton.disabled = isFirstSlide
        prevButton.classList.toggle('disabled', isFirstSlide)
      }

      if (nextButton) {
        nextButton.disabled = isLastSlide
        nextButton.classList.toggle('disabled', isLastSlide)
      }

      if (calendarWrapper) {
        calendarWrapper.classList.toggle('start', isFirstSlide)
        calendarWrapper.classList.toggle('scrolled', !isFirstSlide && !isLastSlide)
        calendarWrapper.classList.toggle('end', isLastSlide)
      }
    })

    glide.mount({ Breakpoints })

    glideElement.querySelectorAll('.glide__arrow--left').forEach((arrow) => {
      arrow.addEventListener('click', () => glide.go('<'))
    })
    glideElement.querySelectorAll('.glide__arrow--right').forEach((arrow) => {
      arrow.addEventListener('click', () => glide.go('>'))
    })
  })
})
